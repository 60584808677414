import React from 'react'
import Loadable from 'react-loadable'

const LoadableEditor = Loadable({
  // loader: () => import('./Froala'),
  loader: () => import('./QuillEditor'),
  loading() {
    return 'Loading...'
  },
})
export default () => <LoadableEditor />
